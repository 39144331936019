import { j as r } from "./jsx-runtime-B6kdoens.js";
import { _ as l, a as A, b as k } from "./index-28E3tTLh.js";
import * as i from "react";
import { forwardRef as f, createElement as s, useRef as u } from "react";
import { $ as I } from "./index-Cp-Nu9_j.js";
import { h as D, b as L, i as V, c as q, d as B, e as G, f as _, g as J, $ as K, a as Q } from "./index-D9Xim34e.js";
import { b as U } from "./index-CTDw4Pi4.js";
import { b as N } from "./Button-ChOUYHf0.js";
import { c as d } from "./utils-CJ9afRe1.js";
const W = "AlertDialog", [X, va] = I(W, [
  D
]), n = D(), Y = (a) => {
  const { __scopeAlertDialog: e, ...t } = a, o = n(e);
  return /* @__PURE__ */ s(J, l({}, o, t, {
    modal: !0
  }));
}, Z = /* @__PURE__ */ f((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ s(K, l({}, c, o, {
    ref: e
  }));
}), aa = (a) => {
  const { __scopeAlertDialog: e, ...t } = a, o = n(e);
  return /* @__PURE__ */ s(Q, l({}, o, t));
}, ea = /* @__PURE__ */ f((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ s(L, l({}, c, o, {
    ref: e
  }));
}), y = "AlertDialogContent", [ta, oa] = X(y), ca = /* @__PURE__ */ f((a, e) => {
  const { __scopeAlertDialog: t, children: o, ...c } = a, p = n(t), b = u(null), H = A(e, b), x = u(null);
  return /* @__PURE__ */ s(V, {
    contentName: y,
    titleName: sa,
    docsSlug: "alert-dialog"
  }, /* @__PURE__ */ s(ta, {
    scope: t,
    cancelRef: x
  }, /* @__PURE__ */ s(q, l({
    role: "alertdialog"
  }, p, c, {
    ref: H,
    onOpenAutoFocus: U(c.onOpenAutoFocus, ($) => {
      var g;
      $.preventDefault(), (g = x.current) === null || g === void 0 || g.focus({
        preventScroll: !0
      });
    }),
    onPointerDownOutside: ($) => $.preventDefault(),
    onInteractOutside: ($) => $.preventDefault()
  }), /* @__PURE__ */ s(k, null, o), !1)));
}), sa = "AlertDialogTitle", ra = /* @__PURE__ */ f((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ s(B, l({}, c, o, {
    ref: e
  }));
}), la = /* @__PURE__ */ f((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ s(G, l({}, c, o, {
    ref: e
  }));
}), na = /* @__PURE__ */ f((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, c = n(t);
  return /* @__PURE__ */ s(_, l({}, c, o, {
    ref: e
  }));
}), da = "AlertDialogCancel", fa = /* @__PURE__ */ f((a, e) => {
  const { __scopeAlertDialog: t, ...o } = a, { cancelRef: c } = oa(da, t), p = n(t), b = A(e, c);
  return /* @__PURE__ */ s(_, l({}, p, o, {
    ref: b
  }));
}), ia = Y, $a = Z, pa = aa, v = ea, R = ca, j = na, w = fa, C = ra, P = la, ba = ia, ga = $a, S = pa, m = i.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  v,
  {
    className: d(
      "fixed inset-0 z-dialog bg-black-primary/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      a
    ),
    ...e,
    ref: t
  }
));
m.displayName = v.displayName;
const O = i.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsxs(S, { children: [
  /* @__PURE__ */ r.jsx(m, { className: "z-full-screen" }),
  /* @__PURE__ */ r.jsx(
    R,
    {
      ref: t,
      className: d(
        "fixed left-[50%] top-[50%] z-full-screen flex flex-col w-full sm:max-w-sm lg:max-w-lg h-screen sm:h-auto sm:max-h-[95%] translate-x-[-50%] translate-y-[-50%] gap-4 bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
        a
      ),
      ...e
    }
  )
] }));
O.displayName = R.displayName;
const T = ({
  className: a,
  ...e
}) => /* @__PURE__ */ r.jsx(
  "div",
  {
    className: d(
      "flex flex-col space-y-2 sm:text-left",
      a
    ),
    ...e
  }
);
T.displayName = "AlertDialogHeader";
const E = ({
  className: a,
  ...e
}) => /* @__PURE__ */ r.jsx(
  "div",
  {
    className: d(
      "flex flex-row justify-end space-x-2 px-3 py-2",
      a
    ),
    ...e
  }
);
E.displayName = "AlertDialogFooter";
const h = i.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  C,
  {
    ref: t,
    className: d("", a),
    ...e
  }
));
h.displayName = C.displayName;
const z = i.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  P,
  {
    ref: t,
    className: d("text-sm text-muted-foreground", a),
    ...e
  }
));
z.displayName = P.displayName;
const F = i.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  j,
  {
    ref: t,
    className: d(N(), a),
    ...e
  }
));
F.displayName = j.displayName;
const M = i.forwardRef(({ className: a, ...e }, t) => /* @__PURE__ */ r.jsx(
  w,
  {
    ref: t,
    className: d(
      N({ variant: "outline" }),
      a
    ),
    ...e
  }
));
M.displayName = w.displayName;
const Ra = Object.assign(ba, {
  Portal: S,
  Overlay: m,
  Trigger: ga,
  Content: O,
  Header: T,
  Footer: E,
  Title: h,
  Description: z,
  Action: F,
  Cancel: M
});
export {
  Ra as A
};
