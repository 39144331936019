import type { FormElementV2ResponseDto } from "src/backend";

import { getChildrenIds } from "../form-elements";

export const getSelectedElementsDetails = (args: {
    selectedElements: FormElementV2ResponseDto[],
    allPackageElements: FormElementV2ResponseDto[],
}) => {
    const loanFormElementsMap = args.allPackageElements.reduce((acc, formElement) => {
        acc[formElement.id] = formElement;
        return acc;
    }, {} as Record<string, FormElementV2ResponseDto>);
    const allFormElementsIds: string[] = []
    // loop throw all selected form elements
    // and get their children ids recursively
    args.selectedElements.forEach((formElement) => {
        const allChildrenIds = getChildrenIds(formElement, args.allPackageElements, true);
        allFormElementsIds.push(formElement.id);
        allFormElementsIds.push(...allChildrenIds);
    })

    // filter out duplicates
    const uniqueElementsIds = [...new Set<string>(allFormElementsIds)];
    // count of form elements with answer
    const totalNotAnswered = uniqueElementsIds.filter((id) => loanFormElementsMap[id].storageType === 'FILE' &&
        !loanFormElementsMap[id].answer).length;
    // get virtual folder form elements
    const virtualFolderFormElementIds = uniqueElementsIds.filter((id) => {
        const formElement = loanFormElementsMap[id];
        return formElement.storageType === 'FOLDER' &&
            formElement.knowledgeBase === 'VIRTUAL_FOLDER';
    })
    // list of virtual folder form elements
    const virtualFolderFormElements = virtualFolderFormElementIds.map((id) => loanFormElementsMap[id]);
    // list of virtual folder form elements names 
    const virtualElementNames = virtualFolderFormElements.map(({ title }) => title);
    // list of non virtual folder form elements
    const nonVirtualElementIds = uniqueElementsIds.filter((id) => !virtualFolderFormElementIds.includes(id))

    return {
        totalNotAnswered,
        uniqueElementsIds,
        virtualElementNames,
        nonVirtualElementIds,
    }
}
