import { j as I } from "./jsx-runtime-B6kdoens.js";
import { $ as vt } from "./index-28E3tTLh.js";
import * as Y from "react";
import o, { useLayoutEffect as ht, useEffect as wt } from "react";
import { b as bt, c as yt, $ as xt, a as $t, f as Et, d as Dt, e as Rt, g as Pt } from "./index-D9Xim34e.js";
import { c as W } from "./utils-CJ9afRe1.js";
var We = o.createContext({ drawerRef: { current: null }, overlayRef: { current: null }, scaleBackground: () => {
}, onPress: () => {
}, onRelease: () => {
}, onDrag: () => {
}, onNestedDrag: () => {
}, onNestedOpenChange: () => {
}, onNestedRelease: () => {
}, openProp: void 0, dismissible: !1, isOpen: !1, keyboardIsOpen: { current: !1 }, snapPointsOffset: null, snapPoints: null, modal: !1, shouldFade: !1, activeSnapPoint: null, onOpenChange: () => {
}, setActiveSnapPoint: () => {
}, visible: !1, closeDrawer: () => {
}, setVisible: () => {
}, direction: "bottom" }), Ee = () => o.useContext(We);
function Tt(e, { insertAt: n } = {}) {
  if (typeof document > "u") return;
  let t = document.head || document.getElementsByTagName("head")[0], r = document.createElement("style");
  r.type = "text/css", n === "top" && t.firstChild ? t.insertBefore(r, t.firstChild) : t.appendChild(r), r.styleSheet ? r.styleSheet.cssText = e : r.appendChild(document.createTextNode(e));
}
Tt(`[vaul-drawer]{touch-action:none;transition:transform .5s cubic-bezier(.32,.72,0,1)}[vaul-drawer][vaul-drawer-direction=bottom]{transform:translate3d(0,100%,0)}[vaul-drawer][vaul-drawer-direction=top]{transform:translate3d(0,-100%,0)}[vaul-drawer][vaul-drawer-direction=left]{transform:translate3d(-100%,0,0)}[vaul-drawer][vaul-drawer-direction=right]{transform:translate3d(100%,0,0)}.vaul-dragging .vaul-scrollable [vault-drawer-direction=top],.vaul-dragging .vaul-scrollable [vault-drawer-direction=bottom]{overflow-y:hidden!important}.vaul-dragging .vaul-scrollable [vault-drawer-direction=left],.vaul-dragging .vaul-scrollable [vault-drawer-direction=right]{overflow-x:hidden!important}[vaul-drawer][vaul-drawer-visible=true][vaul-drawer-direction=top],[vaul-drawer][vaul-drawer-visible=true][vaul-drawer-direction=bottom]{transform:translate3d(0,var(--snap-point-height, 0),0)}[vaul-drawer][vaul-drawer-visible=true][vaul-drawer-direction=left],[vaul-drawer][vaul-drawer-visible=true][vaul-drawer-direction=right]{transform:translate3d(var(--snap-point-height, 0),0,0)}[vaul-overlay]{opacity:0;transition:opacity .5s cubic-bezier(.32,.72,0,1)}[vaul-overlay][vaul-drawer-visible=true]{opacity:1}[vaul-drawer]:after{content:"";position:absolute;background:inherit;background-color:inherit}[vaul-drawer][vaul-drawer-direction=top]:after{top:initial;bottom:100%;left:0;right:0;height:200%}[vaul-drawer][vaul-drawer-direction=bottom]:after{top:100%;bottom:initial;left:0;right:0;height:200%}[vaul-drawer][vaul-drawer-direction=left]:after{left:initial;right:100%;top:0;bottom:0;width:200%}[vaul-drawer][vaul-drawer-direction=right]:after{left:100%;right:initial;top:0;bottom:0;width:200%}[vaul-overlay][vaul-snap-points=true]:not([vaul-snap-points-overlay="true"]):not([data-state="closed"]){opacity:0}[vaul-overlay][vaul-snap-points-overlay=true]:not([vaul-drawer-visible="false"]){opacity:1}@keyframes fake-animation{}@media (hover: hover) and (pointer: fine){[vaul-drawer]{user-select:none}}
`);
var St = typeof window < "u" ? ht : wt;
function ye(...e) {
  return (...n) => {
    for (let t of e) typeof t == "function" && t(...n);
  };
}
function Ot() {
  return De(/^Mac/);
}
function Ct() {
  return De(/^iPhone/);
}
function Nt() {
  return De(/^iPad/) || Ot() && navigator.maxTouchPoints > 1;
}
function Le() {
  return Ct() || Nt();
}
function De(e) {
  return typeof window < "u" && window.navigator != null ? e.test(window.navigator.platform) : void 0;
}
var we = typeof document < "u" && window.visualViewport;
function He(e) {
  let n = window.getComputedStyle(e);
  return /(auto|scroll)/.test(n.overflow + n.overflowX + n.overflowY);
}
function Ue(e) {
  for (He(e) && (e = e.parentElement); e && !He(e); ) e = e.parentElement;
  return e || document.scrollingElement || document.documentElement;
}
var At = /* @__PURE__ */ new Set(["checkbox", "radio", "range", "color", "file", "image", "button", "submit", "reset"]), ce = 0, be;
function Mt(e = {}) {
  let { isDisabled: n } = e;
  St(() => {
    if (!n) return ce++, ce === 1 && (Le() ? be = Ht() : be = It()), () => {
      ce--, ce === 0 && be();
    };
  }, [n]);
}
function It() {
  return ye(qe(document.documentElement, "paddingRight", `${window.innerWidth - document.documentElement.clientWidth}px`));
}
function Ht() {
  let e, n = 0, t = (x) => {
    e = Ue(x.target), !(e === document.documentElement && e === document.body) && (n = x.changedTouches[0].pageY);
  }, r = (x) => {
    if (!e || e === document.documentElement || e === document.body) {
      x.preventDefault();
      return;
    }
    let c = x.changedTouches[0].pageY, p = e.scrollTop, N = e.scrollHeight - e.clientHeight;
    N !== 0 && ((p <= 0 && c > n || p >= N && c < n) && x.preventDefault(), n = c);
  }, i = (x) => {
    let c = x.target;
    fe(c) && c !== document.activeElement && (x.preventDefault(), c.style.transform = "translateY(-2000px)", c.focus(), requestAnimationFrame(() => {
      c.style.transform = "";
    }));
  }, a = (x) => {
    let c = x.target;
    fe(c) && (c.style.transform = "translateY(-2000px)", requestAnimationFrame(() => {
      c.style.transform = "", we && (we.height < window.innerHeight ? requestAnimationFrame(() => {
        je(c);
      }) : we.addEventListener("resize", () => je(c), { once: !0 }));
    }));
  }, u = () => {
    window.scrollTo(0, 0);
  }, f = window.pageXOffset, O = window.pageYOffset, R = ye(qe(document.documentElement, "paddingRight", `${window.innerWidth - document.documentElement.clientWidth}px`));
  window.scrollTo(0, 0);
  let $ = ye(re(document, "touchstart", t, { passive: !1, capture: !0 }), re(document, "touchmove", r, { passive: !1, capture: !0 }), re(document, "touchend", i, { passive: !1, capture: !0 }), re(document, "focus", a, !0), re(window, "scroll", u));
  return () => {
    R(), $(), window.scrollTo(f, O);
  };
}
function qe(e, n, t) {
  let r = e.style[n];
  return e.style[n] = t, () => {
    e.style[n] = r;
  };
}
function re(e, n, t, r) {
  return e.addEventListener(n, t, r), () => {
    e.removeEventListener(n, t, r);
  };
}
function je(e) {
  let n = document.scrollingElement || document.documentElement;
  for (; e && e !== n; ) {
    let t = Ue(e);
    if (t !== document.documentElement && t !== document.body && t !== e) {
      let r = t.getBoundingClientRect().top, i = e.getBoundingClientRect().top, a = e.getBoundingClientRect().bottom, u = t.getBoundingClientRect().bottom;
      a > u && (t.scrollTop += i - r);
    }
    e = t.parentElement;
  }
}
function fe(e) {
  return e instanceof HTMLInputElement && !At.has(e.type) || e instanceof HTMLTextAreaElement || e instanceof HTMLElement && e.isContentEditable;
}
function jt(e, n) {
  typeof e == "function" ? e(n) : e != null && (e.current = n);
}
function kt(...e) {
  return (n) => e.forEach((t) => jt(t, n));
}
function Ye(...e) {
  return Y.useCallback(kt(...e), e);
}
var B = null;
function Ft({ isOpen: e, modal: n, nested: t, hasBeenOpened: r, preventScrollRestoration: i }) {
  let [a, u] = o.useState(typeof window < "u" ? window.location.href : ""), f = o.useRef(0);
  function O() {
    if (B === null && e) {
      B = { position: document.body.style.position, top: document.body.style.top, left: document.body.style.left, height: document.body.style.height };
      let { scrollX: $, innerHeight: x } = window;
      document.body.style.setProperty("position", "fixed", "important"), document.body.style.top = `${-f.current}px`, document.body.style.left = `${-$}px`, document.body.style.right = "0px", document.body.style.height = "auto", setTimeout(() => requestAnimationFrame(() => {
        let c = x - window.innerHeight;
        c && f.current >= x && (document.body.style.top = `${-(f.current + c)}px`);
      }), 300);
    }
  }
  function R() {
    if (B !== null) {
      let $ = -parseInt(document.body.style.top, 10), x = -parseInt(document.body.style.left, 10);
      document.body.style.position = B.position, document.body.style.top = B.top, document.body.style.left = B.left, document.body.style.height = B.height, document.body.style.right = "unset", requestAnimationFrame(() => {
        if (i && a !== window.location.href) {
          u(window.location.href);
          return;
        }
        window.scrollTo(x, $);
      }), B = null;
    }
  }
  return o.useEffect(() => {
    function $() {
      f.current = window.scrollY;
    }
    return $(), window.addEventListener("scroll", $), () => {
      window.removeEventListener("scroll", $);
    };
  }, []), o.useEffect(() => {
    t || !r || (e ? (O(), n || setTimeout(() => {
      R();
    }, 500)) : R());
  }, [e, r, a]), { restorePositionSetting: R };
}
var Xe = /* @__PURE__ */ new WeakMap();
function E(e, n, t = !1) {
  if (!e || !(e instanceof HTMLElement) || !n) return;
  let r = {};
  Object.entries(n).forEach(([i, a]) => {
    if (i.startsWith("--")) {
      e.style.setProperty(i, a);
      return;
    }
    r[i] = e.style[i], e.style[i] = a;
  }), !t && Xe.set(e, r);
}
function oe(e, n) {
  if (!e || !(e instanceof HTMLElement)) return;
  let t = Xe.get(e);
  t && (n ? e.style[n] = t[n] : Object.entries(t).forEach(([r, i]) => {
    e.style[r] = i;
  }));
}
var T = (e) => {
  switch (e) {
    case "top":
    case "bottom":
      return !0;
    case "left":
    case "right":
      return !1;
    default:
      return e;
  }
};
function de(e, n) {
  let t = window.getComputedStyle(e), r = t.transform || t.webkitTransform || t.mozTransform, i = r.match(/^matrix3d\((.+)\)$/);
  return i ? parseFloat(i[1].split(", ")[T(n) ? 13 : 12]) : (i = r.match(/^matrix\((.+)\)$/), i ? parseFloat(i[1].split(", ")[T(n) ? 5 : 4]) : null);
}
function zt(e) {
  return 8 * (Math.log(e + 1) - 2);
}
var y = { DURATION: 0.5, EASE: [0.32, 0.72, 0, 1] }, _e = 0.4;
function Ve(e) {
  let n = o.useRef(e);
  return o.useEffect(() => {
    n.current = e;
  }), o.useMemo(() => (...t) => {
    var r;
    return (r = n.current) == null ? void 0 : r.call(n, ...t);
  }, []);
}
function Bt({ defaultProp: e, onChange: n }) {
  let t = o.useState(e), [r] = t, i = o.useRef(r), a = Ve(n);
  return o.useEffect(() => {
    i.current !== r && (a(r), i.current = r);
  }, [r, i, a]), t;
}
function Wt({ prop: e, defaultProp: n, onChange: t = () => {
} }) {
  let [r, i] = Bt({ defaultProp: n, onChange: t }), a = e !== void 0, u = a ? e : r, f = Ve(t), O = o.useCallback((R) => {
    if (a) {
      let $ = typeof R == "function" ? R(e) : R;
      $ !== e && f($);
    } else i(R);
  }, [a, e, i, f]);
  return [u, O];
}
function Lt({ activeSnapPointProp: e, setActiveSnapPointProp: n, snapPoints: t, drawerRef: r, overlayRef: i, fadeFromIndex: a, onSnapPointChange: u, direction: f = "bottom" }) {
  let [O, R] = Wt({ prop: e, defaultProp: t == null ? void 0 : t[0], onChange: n }), $ = o.useMemo(() => O === (t == null ? void 0 : t[t.length - 1]), [t, O]), x = t && t.length > 0 && (a || a === 0) && !Number.isNaN(a) && t[a] === O || !t, c = o.useMemo(() => {
    var w;
    return (w = t == null ? void 0 : t.findIndex((d) => d === O)) != null ? w : null;
  }, [t, O]), p = o.useMemo(() => {
    var w;
    return (w = t == null ? void 0 : t.map((d) => {
      let v = typeof window < "u", S = typeof d == "string", j = 0;
      if (S && (j = parseInt(d, 10)), T(f)) {
        let z = S ? j : v ? d * window.innerHeight : 0;
        return v ? f === "bottom" ? window.innerHeight - z : -window.innerHeight + z : z;
      }
      let M = S ? j : v ? d * window.innerWidth : 0;
      return v ? f === "right" ? window.innerWidth - M : -window.innerWidth + M : M;
    })) != null ? w : [];
  }, [t]), N = o.useMemo(() => c !== null ? p == null ? void 0 : p[c] : null, [p, c]), A = o.useCallback((w) => {
    var d;
    let v = (d = p == null ? void 0 : p.findIndex((S) => S === w)) != null ? d : null;
    u(v), E(r.current, { transition: `transform ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})`, transform: T(f) ? `translate3d(0, ${w}px, 0)` : `translate3d(${w}px, 0, 0)` }), p && v !== p.length - 1 && v !== a ? E(i.current, { transition: `opacity ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})`, opacity: "0" }) : E(i.current, { transition: `opacity ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})`, opacity: "1" }), R(v !== null ? t == null ? void 0 : t[v] : null);
  }, [r.current, t, p, a, i, R]);
  o.useEffect(() => {
    var w;
    if (e) {
      let d = (w = t == null ? void 0 : t.findIndex((v) => v === e)) != null ? w : null;
      p && d && typeof p[d] == "number" && A(p[d]);
    }
  }, [e, t, p, A]);
  function X({ draggedDistance: w, closeDrawer: d, velocity: v, dismissible: S }) {
    if (a === void 0) return;
    let j = f === "bottom" || f === "right" ? N ?? 0 - w : N ?? 0 + w, M = c === a - 1, z = c === 0, Z = w > 0;
    if (M && E(i.current, { transition: `opacity ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})` }), v > 2 && !Z) {
      S ? d() : A(p[0]);
      return;
    }
    if (v > 2 && Z && p && t) {
      A(p[t.length - 1]);
      return;
    }
    let me = p == null ? void 0 : p.reduce((k, G) => typeof k != "number" || typeof G != "number" ? k : Math.abs(G - j) < Math.abs(k - j) ? G : k), K = T(f) ? window.innerHeight : window.innerWidth;
    if (v > _e && Math.abs(w) < K * 0.4) {
      let k = Z ? 1 : -1;
      if (k > 0 && $) {
        A(p[t.length - 1]);
        return;
      }
      if (z && k < 0 && S && d(), c === null) return;
      A(p[c + k]);
      return;
    }
    A(me);
  }
  function g({ draggedDistance: w }) {
    if (N === null) return;
    let d = f === "bottom" || f === "right" ? N - w : N + w;
    (f === "bottom" || f === "right") && d < p[p.length - 1] || (f === "top" || f === "left") && d > p[p.length - 1] || E(r.current, { transform: T(f) ? `translate3d(0, ${d}px, 0)` : `translate3d(${d}px, 0, 0)` });
  }
  function Q(w, d) {
    if (!t || typeof c != "number" || !p || a === void 0) return null;
    let v = c === a - 1;
    if (c >= a && d) return 0;
    if (v && !d) return 1;
    if (!x && !v) return null;
    let S = v ? c + 1 : c - 1, j = v ? p[S] - p[S - 1] : p[S + 1] - p[S], M = w / Math.abs(j);
    return v ? 1 - M : M;
  }
  return { isLastSnapPoint: $, activeSnapPoint: O, shouldFade: x, getPercentageDragged: Q, setActiveSnapPoint: R, activeSnapPointIndex: c, onRelease: X, onDrag: g, snapPointsOffset: p };
}
var Ut = 0.25, qt = 100, ke = 8, q = 16, Fe = 26, ze = "vaul-dragging";
function Je({ open: e, onOpenChange: n, children: t, shouldScaleBackground: r, onDrag: i, onRelease: a, snapPoints: u, nested: f = !1, closeThreshold: O = Ut, scrollLockTimeout: R = qt, dismissible: $ = !0, fadeFromIndex: x = u && u.length - 1, activeSnapPoint: c, setActiveSnapPoint: p, fixed: N, modal: A = !0, onClose: X, direction: g = "bottom", preventScrollRestoration: Q = !0 }) {
  var w;
  let [d = !1, v] = o.useState(!1), [S, j] = o.useState(!1), [M, z] = o.useState(!1), [Z, me] = o.useState(!1), [K, k] = o.useState(!1), [G, Pe] = o.useState(!1), L = o.useRef(null), ie = o.useRef(null), pe = o.useRef(null), Te = o.useRef(null), ae = o.useRef(null), ee = o.useRef(!1), ge = o.useRef(null), ve = o.useRef(0), _ = o.useRef(!1), Se = o.useRef(0), s = o.useRef(null), Oe = o.useRef(((w = s.current) == null ? void 0 : w.getBoundingClientRect().height) || 0), he = o.useRef(0), ot = o.useCallback((l) => {
    u && l === ne.length - 1 && (ie.current = /* @__PURE__ */ new Date());
  }, []), { activeSnapPoint: it, activeSnapPointIndex: te, setActiveSnapPoint: Ce, onRelease: at, snapPointsOffset: ne, onDrag: lt, shouldFade: Ne, getPercentageDragged: ut } = Lt({ snapPoints: u, activeSnapPointProp: c, setActiveSnapPointProp: p, drawerRef: s, fadeFromIndex: x, overlayRef: L, onSnapPointChange: ot, direction: g });
  Mt({ isDisabled: !d || K || !A || G || !S });
  let { restorePositionSetting: st } = Ft({ isOpen: d, modal: A, nested: f, hasBeenOpened: S, preventScrollRestoration: Q });
  function V() {
    return (window.innerWidth - Fe) / window.innerWidth;
  }
  function ct(l) {
    var m;
    !$ && !u || s.current && !s.current.contains(l.target) || (Oe.current = ((m = s.current) == null ? void 0 : m.getBoundingClientRect().height) || 0, k(!0), pe.current = /* @__PURE__ */ new Date(), Le() && window.addEventListener("touchend", () => ee.current = !1, { once: !0 }), l.target.setPointerCapture(l.pointerId), ve.current = T(g) ? l.screenY : l.screenX);
  }
  function Ae(l, m) {
    var b;
    let h = l, D = (b = window.getSelection()) == null ? void 0 : b.toString(), P = s.current ? de(s.current, g) : null, C = /* @__PURE__ */ new Date();
    if (ie.current && C.getTime() - ie.current.getTime() < 500) return !1;
    if (P !== null && (g === "bottom" || g === "right" ? P > 0 : P < 0)) return !0;
    if (D && D.length > 0) return !1;
    if (ae.current && C.getTime() - ae.current.getTime() < R && P === 0 || m) return ae.current = C, !1;
    for (; h; ) {
      if (h.scrollHeight > h.clientHeight) {
        if (h.scrollTop !== 0) return ae.current = /* @__PURE__ */ new Date(), !1;
        if (h.getAttribute("role") === "dialog") return !0;
      }
      h = h.parentNode;
    }
    return !0;
  }
  function dt(l) {
    if (s.current && K) {
      let m = g === "bottom" || g === "right" ? 1 : -1, b = (ve.current - (T(g) ? l.screenY : l.screenX)) * m, h = b > 0;
      if (u && te === 0 && !$ || !ee.current && !Ae(l.target, h)) return;
      if (s.current.classList.add(ze), ee.current = !0, E(s.current, { transition: "none" }), E(L.current, { transition: "none" }), u && lt({ draggedDistance: b }), h && !u) {
        let F = zt(b), se = Math.min(F * -1, 0) * m;
        E(s.current, { transform: T(g) ? `translate3d(0, ${se}px, 0)` : `translate3d(${se}px, 0, 0)` });
        return;
      }
      let D = Math.abs(b), P = document.querySelector("[vaul-drawer-wrapper]"), C = D / Oe.current, ue = ut(D, h);
      ue !== null && (C = ue);
      let U = 1 - C;
      if ((Ne || x && te === x - 1) && (i == null || i(l, C), E(L.current, { opacity: `${U}`, transition: "none" }, !0)), P && L.current && r) {
        let F = Math.min(V() + C * (1 - V()), 1), se = 8 - C * 8, Ie = Math.max(0, 14 - C * 14);
        E(P, { borderRadius: `${se}px`, transform: T(g) ? `scale(${F}) translate3d(0, ${Ie}px, 0)` : `scale(${F}) translate3d(${Ie}px, 0, 0)`, transition: "none" }, !0);
      }
      if (!u) {
        let F = D * m;
        E(s.current, { transform: T(g) ? `translate3d(0, ${F}px, 0)` : `translate3d(${F}px, 0, 0)` });
      }
    }
  }
  o.useEffect(() => () => {
    le(!1), st();
  }, []), o.useEffect(() => {
    var l;
    function m() {
      var b;
      if (!s.current) return;
      let h = document.activeElement;
      if (fe(h) || _.current) {
        let D = ((b = window.visualViewport) == null ? void 0 : b.height) || 0, P = window.innerHeight - D, C = s.current.getBoundingClientRect().height || 0;
        he.current || (he.current = C);
        let ue = s.current.getBoundingClientRect().top;
        if (Math.abs(Se.current - P) > 60 && (_.current = !_.current), u && u.length > 0 && ne && te) {
          let U = ne[te] || 0;
          P += U;
        }
        if (Se.current = P, C > D || _.current) {
          let U = s.current.getBoundingClientRect().height, F = U;
          U > D && (F = D - Fe), N ? s.current.style.height = `${U - Math.max(P, 0)}px` : s.current.style.height = `${Math.max(F, D - ue)}px`;
        } else s.current.style.height = `${he.current}px`;
        u && u.length > 0 && !_.current ? s.current.style.bottom = "0px" : s.current.style.bottom = `${Math.max(P, 0)}px`;
      }
    }
    return (l = window.visualViewport) == null || l.addEventListener("resize", m), () => {
      var b;
      return (b = window.visualViewport) == null ? void 0 : b.removeEventListener("resize", m);
    };
  }, [te, u, ne]);
  function J() {
    s.current && (X == null || X(), E(s.current, { transform: T(g) ? `translate3d(0, ${g === "bottom" ? "100%" : "-100%"}, 0)` : `translate3d(${g === "right" ? "100%" : "-100%"}, 0, 0)`, transition: `transform ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})` }), E(L.current, { opacity: "0", transition: `opacity ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})` }), le(!1), setTimeout(() => {
      z(!1), v(!1);
    }, 300), setTimeout(() => {
      oe(document.documentElement, "scrollBehavior"), u && Ce(u[0]);
    }, y.DURATION * 1e3));
  }
  o.useEffect(() => {
    if (!d && r) {
      let l = setTimeout(() => {
        oe(document.body);
      }, 200);
      return () => clearTimeout(l);
    }
  }, [d, r]), o.useEffect(() => {
    e ? (v(!0), j(!0)) : J();
  }, [e]), o.useEffect(() => {
    Z && (n == null || n(d));
  }, [d]), o.useEffect(() => {
    me(!0);
  }, []);
  function Me() {
    if (!s.current) return;
    let l = document.querySelector("[vaul-drawer-wrapper]"), m = de(s.current, g);
    E(s.current, { transform: "translate3d(0, 0, 0)", transition: `transform ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})` }), E(L.current, { transition: `opacity ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})`, opacity: "1" }), r && m && m > 0 && d && E(l, { borderRadius: `${ke}px`, overflow: "hidden", ...T(g) ? { transform: `scale(${V()}) translate3d(0, calc(env(safe-area-inset-top) + 14px), 0)`, transformOrigin: "top" } : { transform: `scale(${V()}) translate3d(calc(env(safe-area-inset-top) + 14px), 0, 0)`, transformOrigin: "left" }, transitionProperty: "transform, border-radius", transitionDuration: `${y.DURATION}s`, transitionTimingFunction: `cubic-bezier(${y.EASE.join(",")})` }, !0);
  }
  function ft(l) {
    var m;
    if (!K || !s.current) return;
    ee.current && fe(l.target) && l.target.blur(), s.current.classList.remove(ze), ee.current = !1, k(!1), Te.current = /* @__PURE__ */ new Date();
    let b = de(s.current, g);
    if (!Ae(l.target, !1) || !b || Number.isNaN(b) || pe.current === null) return;
    let h = Te.current.getTime() - pe.current.getTime(), D = ve.current - (T(g) ? l.screenY : l.screenX), P = Math.abs(D) / h;
    if (P > 0.05 && (Pe(!0), setTimeout(() => {
      Pe(!1);
    }, 200)), u) {
      at({ draggedDistance: D * (g === "bottom" || g === "right" ? 1 : -1), closeDrawer: J, velocity: P, dismissible: $ }), a == null || a(l, !0);
      return;
    }
    if (g === "bottom" || g === "right" ? D > 0 : D < 0) {
      Me(), a == null || a(l, !0);
      return;
    }
    if (P > _e) {
      J(), a == null || a(l, !1);
      return;
    }
    let C = Math.min((m = s.current.getBoundingClientRect().height) != null ? m : 0, window.innerHeight);
    if (b >= C * O) {
      J(), a == null || a(l, !1);
      return;
    }
    a == null || a(l, !0), Me();
  }
  o.useEffect(() => {
    d && (E(document.documentElement, { scrollBehavior: "auto" }), ie.current = /* @__PURE__ */ new Date(), le(!0));
  }, [d]), o.useEffect(() => {
    var l;
    if (s.current && M) {
      let m = (l = s == null ? void 0 : s.current) == null ? void 0 : l.querySelectorAll("*");
      m == null || m.forEach((b) => {
        let h = b;
        (h.scrollHeight > h.clientHeight || h.scrollWidth > h.clientWidth) && h.classList.add("vaul-scrollable");
      });
    }
  }, [M]);
  function le(l) {
    let m = document.querySelector("[vaul-drawer-wrapper]");
    !m || !r || (l ? (E(document.body, { background: "black" }, !0), E(m, { borderRadius: `${ke}px`, overflow: "hidden", ...T(g) ? { transform: `scale(${V()}) translate3d(0, calc(env(safe-area-inset-top) + 14px), 0)`, transformOrigin: "top" } : { transform: `scale(${V()}) translate3d(calc(env(safe-area-inset-top) + 14px), 0, 0)`, transformOrigin: "left" }, transitionProperty: "transform, border-radius", transitionDuration: `${y.DURATION}s`, transitionTimingFunction: `cubic-bezier(${y.EASE.join(",")})` })) : (oe(m, "overflow"), oe(m, "transform"), oe(m, "borderRadius"), E(m, { transitionProperty: "transform, border-radius", transitionDuration: `${y.DURATION}s`, transitionTimingFunction: `cubic-bezier(${y.EASE.join(",")})` })));
  }
  function mt(l) {
    let m = l ? (window.innerWidth - q) / window.innerWidth : 1, b = l ? -q : 0;
    ge.current && window.clearTimeout(ge.current), E(s.current, { transition: `transform ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})`, transform: `scale(${m}) translate3d(0, ${b}px, 0)` }), !l && s.current && (ge.current = setTimeout(() => {
      let h = de(s.current, g);
      E(s.current, { transition: "none", transform: T(g) ? `translate3d(0, ${h}px, 0)` : `translate3d(${h}px, 0, 0)` });
    }, 500));
  }
  function pt(l, m) {
    if (m < 0) return;
    let b = T(g) ? window.innerHeight : window.innerWidth, h = (b - q) / b, D = h + m * (1 - h), P = -q + m * q;
    E(s.current, { transform: T(g) ? `scale(${D}) translate3d(0, ${P}px, 0)` : `scale(${D}) translate3d(${P}px, 0, 0)`, transition: "none" });
  }
  function gt(l, m) {
    let b = T(g) ? window.innerHeight : window.innerWidth, h = m ? (b - q) / b : 1, D = m ? -q : 0;
    m && E(s.current, { transition: `transform ${y.DURATION}s cubic-bezier(${y.EASE.join(",")})`, transform: T(g) ? `scale(${h}) translate3d(0, ${D}px, 0)` : `scale(${h}) translate3d(${D}px, 0, 0)` });
  }
  return o.createElement(Pt, { modal: A, onOpenChange: (l) => {
    if (e !== void 0) {
      n == null || n(l);
      return;
    }
    l ? (j(!0), v(l)) : J();
  }, open: d }, o.createElement(We.Provider, { value: { visible: M, activeSnapPoint: it, snapPoints: u, setActiveSnapPoint: Ce, drawerRef: s, overlayRef: L, scaleBackground: le, onOpenChange: n, onPress: ct, setVisible: z, onRelease: ft, onDrag: dt, dismissible: $, isOpen: d, shouldFade: Ne, closeDrawer: J, onNestedDrag: pt, onNestedOpenChange: mt, onNestedRelease: gt, keyboardIsOpen: _, openProp: e, modal: A, snapPointsOffset: ne, direction: g } }, t));
}
var Qe = o.forwardRef(function({ children: e, ...n }, t) {
  let { overlayRef: r, snapPoints: i, onRelease: a, shouldFade: u, isOpen: f, visible: O } = Ee(), R = Ye(t, r), $ = i && i.length > 0;
  return o.createElement(bt, { onMouseUp: a, ref: R, "vaul-drawer-visible": O ? "true" : "false", "vaul-overlay": "", "vaul-snap-points": f && $ ? "true" : "false", "vaul-snap-points-overlay": f && u ? "true" : "false", ...n });
});
Qe.displayName = "Drawer.Overlay";
var Ze = o.forwardRef(function({ onOpenAutoFocus: e, onPointerDownOutside: n, onAnimationEnd: t, style: r, ...i }, a) {
  let { drawerRef: u, onPress: f, onRelease: O, onDrag: R, dismissible: $, keyboardIsOpen: x, snapPointsOffset: c, visible: p, closeDrawer: N, modal: A, openProp: X, onOpenChange: g, setVisible: Q, direction: w } = Ee(), d = Ye(a, u);
  return o.useEffect(() => {
    Q(!0);
  }, []), o.createElement(yt, { onOpenAutoFocus: (v) => {
    var S;
    e ? e(v) : (v.preventDefault(), (S = u.current) == null || S.focus());
  }, onPointerDown: f, onPointerDownOutside: (v) => {
    if (n == null || n(v), !A) {
      v.preventDefault();
      return;
    }
    x.current && (x.current = !1), v.preventDefault(), g == null || g(!1), !(!$ || X !== void 0) && N();
  }, onPointerMove: R, onPointerUp: O, ref: d, style: c && c.length > 0 ? { "--snap-point-height": `${c[0]}px`, ...r } : r, ...i, "vaul-drawer": "", "vaul-drawer-direction": w, "vaul-drawer-visible": p ? "true" : "false" });
});
Ze.displayName = "Drawer.Content";
function Yt({ onDrag: e, onOpenChange: n, ...t }) {
  let { onNestedDrag: r, onNestedOpenChange: i, onNestedRelease: a } = Ee();
  if (!r) throw new Error("Drawer.NestedRoot must be placed in another drawer");
  return o.createElement(Je, { nested: !0, onClose: () => {
    i(!1);
  }, onDrag: (u, f) => {
    r(u, f), e == null || e(u, f);
  }, onOpenChange: (u) => {
    u && i(u), n == null || n(u);
  }, onRelease: a, ...t });
}
var H = { Root: Je, NestedRoot: Yt, Content: Ze, Overlay: Qe, Trigger: xt, Portal: $t, Close: Et, Title: Dt, Description: Rt };
const xe = ({
  shouldScaleBackground: e = !0,
  modal: n,
  ...t
}) => /* @__PURE__ */ I.jsx(
  H.Root,
  {
    shouldScaleBackground: e,
    ...t
  }
);
xe.displayName = "Drawer";
const Be = H.Trigger, Ke = H.Portal, Xt = H.Close, Re = Y.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ I.jsx(
  H.Overlay,
  {
    ref: t,
    className: W("fixed inset-0 z-splash bg-black-primary/80 backdrop-blur-sm", e),
    ...n
  }
));
Re.displayName = H.Overlay.displayName;
const $e = Y.forwardRef(({ className: e, children: n, withPortal: t, alignOffset: r, ...i }, a) => /* @__PURE__ */ I.jsxs(Ke, { children: [
  /* @__PURE__ */ I.jsx(Re, {}),
  /* @__PURE__ */ I.jsx(
    H.Content,
    {
      ref: a,
      className: W(
        "fixed inset-x-0 after:hidden bottom-0 z-splash mt-24 flex h-auto max-h-full overflow-y-auto scrollbar-stable gap-4 w-full flex-col rounded-t-[10px] border bg-background",
        e
      ),
      ...i,
      children: n
    }
  )
] }));
$e.displayName = "DrawerContent";
const Ge = ({
  className: e,
  ...n
}) => /* @__PURE__ */ I.jsx(
  "div",
  {
    className: W("grid gap-1.5 p-4 text-center sm:text-left", e),
    ...n
  }
);
Ge.displayName = "DrawerHeader";
const et = Y.forwardRef((e, n) => {
  const { className: t, asChild: r, onSelect: i, ...a } = e, u = r ? vt : "div";
  return /* @__PURE__ */ I.jsx(H.Close, { asChild: !0, children: /* @__PURE__ */ I.jsx(
    u,
    {
      ref: n,
      onClick: (f) => {
        e.onClick && e.onClick(f), i && (i == null || i(f));
      },
      className: W(
        "relative flex cursor-pointer select-none items-center rounded-sm px-2 data-[selected=true]:bg-blue-50 py-1.5 text-sm outline-none transition-colors focus:bg-accent focus:text-accent-foreground hover:bg-accent min-h-9 data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-50",
        t
      ),
      ...a
    }
  ) });
}), _t = Y.forwardRef((e, n) => {
  const { className: t, ...r } = e;
  return /* @__PURE__ */ I.jsx(
    "div",
    {
      ref: n,
      className: W(
        "px-2 py-1.5 text-sm font-semibold",
        t
      ),
      ...r
    }
  );
});
et.displayName = "DrawerItem";
const tt = ({
  className: e,
  ...n
}) => /* @__PURE__ */ I.jsx(
  "div",
  {
    className: W("mt-auto flex flex-col gap-2 p-4", e),
    ...n
  }
);
tt.displayName = "DrawerFooter";
const nt = Y.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ I.jsx(
  H.Title,
  {
    ref: t,
    className: W(
      "text-lg font-semibold leading-none tracking-tight",
      e
    ),
    ...n
  }
));
nt.displayName = H.Title.displayName;
const rt = Y.forwardRef(({ className: e, ...n }, t) => /* @__PURE__ */ I.jsx(
  H.Description,
  {
    ref: t,
    className: W("text-sm text-muted-foreground", e),
    ...n
  }
));
rt.displayName = H.Description.displayName;
const Gt = Object.assign(xe, {
  Trigger: Be,
  Portal: Ke,
  Close: Xt,
  Label: _t,
  Overlay: Re,
  Content: $e,
  Item: et,
  Header: Ge,
  Footer: tt,
  Title: nt,
  Description: rt,
  Sub: xe,
  SubTrigger: Be,
  SubContent: $e
});
export {
  Gt as D
};
